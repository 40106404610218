import styled, { css } from 'styled-components';

import { Text } from 'components/Atoms/Text/Text';

export const StyledText = styled(Text)<{ isSubItem: boolean }>`
  position: absolute;
  left: 10px;
  top: 95%;
  transform: rotate(-90deg);
  transform-origin: top left;
  z-index: ${({ theme }) => theme.zIndex.level3};

  ${({ theme }) => theme.mq.phone} {
    top: calc(100% - 20px);
    left: 20px;
  }

  ${({ isSubItem }) =>
    isSubItem &&
    css`
      height: auto;
      opacity: 0.2;
      top: 70px;
      left: 10px;
      bottom: auto;

      ${({ theme }) => theme.mq.phone} {
        top: 100px;
        left: 20px;
      }

      ${({ theme }) => theme.mq.tablet} {
        top: 230px;
      }
    `}
`;
