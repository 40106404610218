import React, { FC } from 'react';
//@ts-ignore
import { HelmetDatoCms } from 'gatsby-source-datocms';
import type { Locale } from 'types';

interface SEOProps {
  lang: Locale;
  seo: unknown;
  keywords?: string;
}

export const SEO: FC<SEOProps> = ({ seo, lang = 'pl', keywords, children, ...props }) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <HelmetDatoCms
      seo={seo}
      htmlAttributes={{
        lang,
      }}
      {...props}
    >
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Mateusz Hadryś" />
      <meta name="google-site-verification" content="mvHCWkLFJnkDZZCT7CLnuokrJgh1cL9Ol1LvIGj6C-8" />
      {keywords && <meta name="keywords" content={keywords} />}
      <link rel="canonical" href={url} />
      {children}
    </HelmetDatoCms>
  );
};
