import React, { FC } from 'react';

import { CTA } from 'components/Atoms/CTA/CTA';
import { Words } from 'components/Organisms/Words/Words';
import { StyledText } from './SideNavItem.style';

interface SideNavItemProps {
  isSubItem?: boolean;
  data: {
    label?: string;
    path?: string;
  };
}

export const SideNavItem: FC<SideNavItemProps> = ({ data, isSubItem = false }) => {
  const { label, path = '/' } = data || {};

  return (
    <CTA to={path.includes('/') ? path : `/${path}`} aria-label={label}>
      <StyledText
        noWrap
        tag="span"
        color="textLight"
        isUpper
        letterSpacing="1px"
        variant="heading"
        isSubItem={isSubItem}
      >
        <Words exit={{ opacity: 0 }}>{label}</Words>
      </StyledText>
    </CTA>
  );
};
