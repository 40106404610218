import React, { FC } from 'react';

import { SideNavItem } from 'components/Atoms/SideNavItem/SideNavItem';
import { useLocaleState } from 'providers/LocaleProvider/LocaleProvider';
import { getItemData } from 'helpers';
interface SideNavProps {
  mainItem?: any | null;
  subItem?: any | null;
  withSubLink?: boolean;
}

export const SideNav: FC<SideNavProps> = ({
  mainItem,
  subItem = null,
  withSubLink = true,
  ...rest
}) => {
  const { activeLocale } = useLocaleState();

  return (
    <>
      <SideNavItem data={getItemData(mainItem, activeLocale)} {...rest} />
      {withSubLink && <SideNavItem isSubItem data={getItemData(subItem, activeLocale)} {...rest} />}
    </>
  );
};
